.steps-steps-list {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.steps-section10 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text10 {
  display: inline-block;
}
.steps-caption10 {
  font-size: 24px;
}
.steps-divider1 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section11 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text13 {
  display: inline-block;
}
.steps-caption11 {
  font-size: 24px;
}
.steps-divider2 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section12 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text14 {
  display: inline-block;
}
.steps-caption12 {
  font-size: 24px;
}
.steps-divider3 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text15 {
  display: inline-block;
}
.steps-caption13 {
  font-size: 24px;
}
.steps-divider4 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section14 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text16 {
  color: var(--dl-color-gray-black);
  display: inline-block;
}
.steps-caption14 {
  font-size: 24px;
}
.steps-divider5 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section15 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text17 {
  color: var(--dl-color-gray-black);
  display: inline-block;
}
.steps-caption15 {
  font-size: 24px;
}
.steps-divider6 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section16 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text18 {
  color: var(--dl-color-gray-black);
  display: inline-block;
}
.steps-caption16 {
  font-size: 24px;
}
.steps-divider7 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section17 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text19 {
  color: var(--dl-color-gray-black);
  display: inline-block;
}
.steps-caption17 {
  font-size: 24px;
}
.steps-divider8 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section18 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text20 {
  display: inline-block;
}
.steps-divider9 {
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  border-color: #222223;
  border-style: dotted;
  border-width: 2px;
  margin-right: 40px;
  flex-direction: column;
  justify-content: space-between;
}
.steps-section19 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.steps-text23 {
  display: inline-block;
}
.steps-caption20 {
  display: inline-block;
  font-size: 24px;
}
.steps-caption21 {
  display: inline-block;
  font-size: 24px;
}
@media(max-width: 767px) {
  .steps-steps-list {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .steps-text10 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption10 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider1 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text13 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption11 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider2 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text14 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption12 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider3 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text15 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption13 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider4 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text16 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption14 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider5 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text17 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption15 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider6 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text18 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption16 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider7 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text19 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption17 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-divider8 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text20 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-text21 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-divider9 {
    height: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .steps-text23 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-text24 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
  .steps-caption20 {
    font-size: 16px;
    line-height: 40px;
  }
  .steps-caption21 {
    font-size: 16px;
    line-height: 40px;
  }
}
