.footer31-footer4 {
  gap: 80px;
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.footer31-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer31-content {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.footer31-logo {
  gap: 24px;
  width: auto;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.footer31-image1 {
  height: 2rem;
}
.footer31-links {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
}
.footer31-link1 {
  color: #ffffff;
}
.footer31-link2 {
  color: #ffffff;
}
.footer31-link3 {
  color: #ffffff;
}
.footer31-link4 {
  color: #fffdfd;
}
.footer31-link5 {
  color: #ffffff;
}
.footer31-social-links {
  gap: var(--dl-space-space-unit);
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}
.footer31-icon10 {
  fill: #ffffff;
}
.footer31-icon12 {
  fill: #ffffff;
}
.footer31-icon14 {
  fill: #ffffff;
}
.footer31-icon16 {
  fill: #ffffff;
}
.footer31-icon18 {
  fill: #ffffff;
}
.footer31-credits {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: column;
  border-top-width: 0.5px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.footer31-row {
  gap: 24px;
  display: flex;
  align-items: flex-start;
}
.footer31-footer-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
}
.footer31-content3 {
  color: #ffffff;
}
.footer31-link11 {
  color: #ffffff;
}
.footer31-link12 {
  color: #ffffff;
}
.footer31-link13 {
  color: #ffffff;
}
.footer31-text1 {
  display: inline-block;
}
.footer31-text2 {
  display: inline-block;
}
.footer31-text3 {
  display: inline-block;
}
.footer31-text4 {
  display: inline-block;
}
.footer31-text5 {
  display: inline-block;
}
.footer31-text6 {
  display: inline-block;
}
.footer31-text7 {
  display: inline-block;
}
.footer31-text8 {
  display: inline-block;
}
.footer31root-class-name {
  background-color: #040404;
}
@media(max-width: 991px) {
  .footer31-logo {
    width: auto;
  }
}
@media(max-width: 767px) {
  .footer31-content {
    flex-direction: column;
  }
  .footer31-row {
    flex-direction: column;
  }
  .footer31-links {
    margin-bottom: 70px;
  
  }
  .footer31-footer-links {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .footer31-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .footer31-content {
    width: 100%;
  }
  
  .footer31-links {
    display: none;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}
