.number-number {
  position: relative;
  background-color: #f3d9d9;
}
.number-text2 {
  display: inline-block;
}
.numberroot-class-name {
  background-color: #E8E6DA;
}
.numberroot-class-name1 {
  background-color: #D0DFA6;
}
.numberroot-class-name2 {
  background-color: #79D490;
}
.numberroot-class-name3 {
  fill: var(--dl-color-gray-black);
  color: #ffffff;
  background-color: #2852e7;
}
.numberroot-class-name4 {
  fill: var(--dl-color-gray-black);
  color: #ffffff;
  background-color: #a6e728;
}
.numberroot-class-name5 {
  fill: var(--dl-color-gray-black);
  color: #ffffff;
  background-color: #df28e7;
}
.numberroot-class-name6 {
  fill: var(--dl-color-gray-black);
  color: #ffffff;
  background-color: #28e795;
}
.numberroot-class-name7 {
  fill: var(--dl-color-gray-black);
  color: #000000;
  background-color: #22a1d2;
}
.numberroot-class-name8 {
  fill: var(--dl-color-gray-black);
  color: #000000;
  background-color: #e39b40;
}
@media(max-width: 479px) {
  .number-number {
    width: 40px;
    height: 40px;
  }
  .number-text2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }
}
