.gallery4-gallery3 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery4-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.gallery4-section-title {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.gallery4-text1 {
  text-align: center;
}
.gallery4-text2 {
  text-align: center;
}
.gallery4-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.gallery4-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.gallery4-container2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery4-image1 {
  width: 100%;
  height: 904px;
  max-width: 100%;
  align-self: center;
}
.gallery4-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.gallery4-image2 {
  width: 100%;
  height: 440px;
  object-fit: cover;
}
.gallery4-image3 {
  width: 100%;
  height: 440px;
  object-fit: cover;
}
.gallery4-text3 {
  display: inline-block;
}
.gallery4-text4 {
  display: inline-block;
}
@media(max-width: 991px) {
  .gallery4-content {
    align-items: center;
    flex-direction: column;
  }
  .gallery4-image1 {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
  }
  .gallery4-container3 {
    width: 100%;
  }
  .gallery4-image2 {
    height: 100%;
  }
  .gallery4-image3 {
    height: 100%;
  }
}
@media(max-width: 767px) {
  .gallery4-section-title {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .gallery4-gallery3 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
}
