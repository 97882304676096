.banner-banner {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #0F1015;
}
.banner-captions {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.banner-caption1 {
  gap: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
}
.banner-text1 {
  color: rgb(246, 245, 237);
  font-size: 14px;
  text-align: center;
}
.banner-link1 {
  color: rgb(246, 245, 237);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 700;
  text-decoration: none;
}
.banner-link1:hover {
  color: grey;
}
.banner-link2 {
  display: contents;
}
.banner-caption2 {
  gap: 4px;
  width: 100%;
  display: none;
  align-items: center;
  padding-left: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: center;
}
.banner-text2 {
  color: rgb(246, 245, 237);
  font-size: 14px;
  text-align: center;
}
.banner-text3 {
  color: rgb(246, 245, 237);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  transition: 0.3s;
  font-weight: 700;
}
.banner-text3:hover {
  color: grey;
}
.banner-close {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.banner-icon1 {
  fill: #ffffff;
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
  transition: 0.3s;
}
.banner-icon1:hover {
  fill: grey;
}
@media(max-width: 479px) {
  .banner-caption1 {
    display: none;
  }
  .banner-caption2 {
    display: flex;
    padding-left: 0px;
  }
  .banner-icon1 {
    display: none;
  }
}
