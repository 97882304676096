

.css-7vq5hu{font:var(--podium-cds-typography-960-to-1919-display2);color:var(--podium-cds-color-text-primary);text-transform:uppercase;font-weight:500;display:undefined;}

@media (max-width:959px){.css-7vq5hu{font:var(--podium-cds-typography-320-to-959-display2);}}
@media (min-width:1920px){.css-7vq5hu{font:var(--podium-cds-typography-1920-plus-display2);}}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){.css-7vq5hu{text-align:left;}
.css-7vq5hu{text-align:right;}}

.contact-form5-contact1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form5-section-title {
  gap: var(--dl-space-space-unit);
  align-items: center;
}

.contact-form5-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-form5-form {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
}
.contact-form5-container1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-form5-input1 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form5-input2 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form5-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.contact-form5-input3 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form5-input4 {
  gap: var(--dl-space-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form5-container3 {
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form5-input5 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form5-container4 {
  gap: var(--dl-space-space-halfunit);
  flex: 0 0 auto;
  width: 50%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form5-checkbox1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: center;
}
.contact-form5-button {
  align-self: flex-start;
}
.contact-form5-text20 {
  display: inline-block;
}
.contact-form5-text21 {
  display: inline-block;
}
.contact-form5-text22 {
  display: inline-block;
}
.contact-form5-text23 {
  display: inline-block;
}

@media(max-width: 991px) {
  .contact-form5-container1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form5-input1 {
    width: 50%;
  }
  .contact-form5-input2 {
    width: 50%;
    flex-direction: column;
  }
  .contact-form5-container2 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form5-input3 {
    width: 50%;
  }
  .contact-form5-input4 {
    width: 50%;
  }
  .contact-form5-container3 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .contact-form5-input5 {
    gap: var(--dl-space-space-halfunit);
    width: 50%;
    flex-direction: column;
  }
  .contact-form5-container4 {
    width: 100%;
  }
  .contact-form5-checkbox1 {
    align-self: flex-start;
  }
  


}

@media(max-width: 767px) {
  
  .contact-form5-textarea {
    flex: 1;
  }
}
@media(max-width: 479px) {
  .contact-form5-text12 {
    text-align: center;
  }
  
  .contact-form5-form {
    padding: var(--dl-space-space-unit);
  }
  .contact-form5-container1 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form5-input1 {
    width: 100%;
  }
  .contact-form5-input2 {
    width: 100%;
  }
  .contact-form5-container2 {
    align-items: stretch;
    flex-direction: column;
  }
  .contact-form5-input3 {
    width: 100%;
  }
  .contact-form5-input4 {
    width: 100%;
  }
  .contact-form5-contact1{
    width: 90%;
    margin: auto;
  }
}
.successCourseProgram{
  background-color: #00ff59;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}