.branding-branding {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
}
.branding-logo {
  width: 46px;
  height: 46px;
  object-fit: cover;
}
.branding-company-name {
  color: rgb(34, 34, 35);
}


@media(max-width: 767px) {
  .branding-logo {
    width: 40px;
    height: 40px;
  }
  .branding-company-name {
    font-size: 21px;
    line-height: 32px;
  }
}
